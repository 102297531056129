import React, { Component } from 'react'

export default class AppErrorBoundry extends Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <div className="h-screen bg-blue-200 flex justify-end items-center prose max-w-none">
            <div>
              <h1>Something went wrong.</h1>
            </div>
          </div>

        </>
      )

        ;
    }

    return this.props.children;
  }
}
