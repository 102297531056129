import { Button, Drawer, List, ListItem, ListItemAvatar, ListItemText, MuiThemeProvider } from "@material-ui/core"
/* eslint-disable */

import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { Fragment } from "react"
// import useSiteMetadata from "./hooks/useSiteMetadata"
import { useState } from "react";
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';


import theme from '../../assets/theme'



const Header = ({ siteTitle }) => {



  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <div>
          <header className={"flex items-center justify-between px-5 sm:px-10 z-50  border-t-8 border-purple-500  h-20 w-full fixed bg-opacity-50 bg-gray-100 "}>

            <div>
              <Link to="/" className="flex justify-center items-center text-purple-500  text-2xl sm:text-3xl">
                MyZone
              </Link>
            </div>
            <div className=" p-4">
              <div className="  font-bold ">
                <Link to="/" className="flex justify-center items-center  text-2xl sm:text-3xl">
                  <img className="h-16 w-16" src="/logo.png" alt="website logo with a a boy in dhayan on lotus with om symbol" />
                  {/* <div className="app-square">{title}</div> */}
                </Link>
              </div>
            </div>

            <div className="text-purple-500 bg-purple-500">
              {/* <Search /> */}
              <a href="/admin" className="bg-purple-500 text-white px-3 py-3 rounded-md uppercase font-bold">
                Login
              </a>
            </div>
          </header>
        </div>
      </MuiThemeProvider >
    </div >

  )


}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `ashramblr`,
}

export default Header
