import React, { useContext } from 'react'
import { Link } from 'gatsby';
import { AppContext } from './context/SettingContext'
import YouTubeIcon from '@material-ui/icons/YouTube'
const Footer = (props) => {

    const setting = useContext(AppContext)
    const { title } = props;
    let {  socialMedia } = setting;
    let social = socialMedia[0];


    
    return (
        <footer className="bg-purple-800 text-gray-700 ">
            <div className="container px-5 py-24 mx-auto">
                <div className="flex flex-wrap justify-around md:text-left text-center -mb-10 -mx-4">
                    <div className="w-64 flex-shrink-0  ">
                        <div className="flex  flex-col text-center justify-center items-center">
                            <Link to="/" className="w-full title-font font-medium  text-gray-900">
                                <img src="/logo.png" alt="website logo" className="h-24 w-24 mx-auto" />
                            </Link>
                            <div className="w-full">
                                <span className="ml-2 text-2xl font-bold text-white">{title}</span>
                                <br/>
                                <span className="text-secondary-700 text-2xl font-bold">MyZone</span>
                            </div>
                            <p className="w-full mt-2 text-sm text-gray-200 tracking-wide">
                                Saint Shri Asharam ji Bapu Ashram Bengaluru
                        </p>
                        </div>
                    </div>
                    <div className="lg:w-1/6 md:w-1/2 w-full px-4">
                        <h2 className="title-font font-medium text-gray-300 tracking-widest text-sm mb-3">Narayan</h2>
                        <nav className="list-none mb-10">
                            <li>
                                <Link to="https://ashramblr.org/p/about-us" className="text-gray-100 hover:text-secondary">About Us</Link>
                            </li>
                            <li>
                                <Link to="https://ashramblr.org/p/contact-us" className="text-gray-100 hover:text-secondary">Contact Us</Link>
                            </li>
                            <li>
                                <Link to="https://ashramblr.org/p/services" className="text-gray-100 hover:text-secondary">Our Services</Link>
                            </li>
                            <li>
                                <Link to="https://ashramblr.org/p/mahila-mandal" className="text-gray-100 hover:text-secondary">Mahila Uthan Mandal</Link>
                            </li>
                        </nav>
                    </div>
                    <div className="lg:w-1/6 md:w-1/2 w-full px-4">
                        <h2 className="title-font font-medium text-gray-300 tracking-widest text-sm mb-3">Bal Sanskar</h2>
                        <nav className="list-none mb-10">
                            <li>
                                <Link to="/" className="text-gray-100 hover:text-secondary">Effective Memory</Link>
                            </li>
                            <li>
                                <Link to="/" className="text-gray-100 hover:text-secondary">Trikal Sandhya</Link>
                            </li>
                            <li>
                                <a href="/" className="text-gray-100 hover:text-secondary">MangalMay Live</a>
                            </li>
                            <li>
                                <a href="/" className="text-gray-100 hover:text-secondary">Our official Youtube</a>
                            </li>
                        </nav>
                    </div>
                    <div className="lg:w-1/6 md:w-1/2 w-full px-4">
                        <h2 className="title-font font-medium text-gray-300 tracking-widest text-sm mb-3">Our Policies</h2>
                        <nav className="list-none mb-10">
                            <li>
                                <Link to="https://ashramblr.org/p/privacy-policy/" className="text-gray-100 hover:text-secondary">Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to="https://ashramblr.org/p/terms-of-service" className="text-gray-100 hover:text-secondary">Terms of Service</Link>
                            </li>
                            <li>
                                <Link to="https://ashramblr.org/p/disclaimer/" className="text-gray-100 hover:text-secondary">Disclaimer</Link>
                            </li>
                            <li>
                                <Link to="https://ashramblr.org/p/disclaimer/" className="text-gray-100 hover:text-secondary">Cookie Policy</Link>
                            </li>
                        </nav>
                    </div>


                </div>
            </div>

            <div className="border-t border-gray-200">
                <div className="container px-5 py-8  flex flex-wrap mx-auto items-center">
                    <div className="flex md:flex-no-wrap flex-wrap justify-center md:justify-start">
                        <p className="text-gray-200 text-sm text-center sm:text-left">© 2020 Saint Shri Asharam ji ashram, Bengaluru —
                        <a href={`https://twitter.com/${social.twitter}`} className="text-gray-100 ml-1" target="_blank" rel="noopener noreferrer">@{social.twitter}</a>
                        </p>
                    </div>
                    <span className="inline-flex lg:ml-auto lg:mt-0 mt-6 w-full justify-center md:justify-start md:w-auto">
                        <a rel="noreferrer" href={`https://facebook.com/${social.fb}`} className="text-gray-500 cursor-pointer" target="_blank">
                            <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                            </svg>
                        </a>
                        <a rel="noreferrer" href={`https://twitter.com/${social.twitter}`} className="ml-3 text-gray-500 cursor-pointer" target="_blank">
                            <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                            </svg>
                        </a>
                        {/* <a rel="noreferrer" href={`https://instagram.com/${social.insta}`} className="ml-3 text-gray-500 cursor-pointer" target="_blank">
                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                            </svg>
                        </a> */}

                        <a rel="noreferrer" href={`https://youtube.com/${social.youtube}`} className="ml-3 text-gray-500 cursor-pointer" target="_blank">
                            <YouTubeIcon />
                        </a>
                    </span>
                </div>

            </div>


        </footer>
    )
}

export default Footer
