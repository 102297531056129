import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';


let theme = createMuiTheme({
    palette: {
        primary: {
            main: '#272343',
        },
        secondary: {
            main: '#ffd903',
        },
    },
    overrides: {
        MuiCard: {
            root: {
                // '&:hover':{
                //     transform: 'scale(1.05)',
                //     transition: 'width .3s ease-in-out'
                // }
            }
        }
    }
});


export default theme = responsiveFontSizes(theme);