
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header/header"
import { ThemeProvider } from "@material-ui/core"
import theme from "../assets/theme"
import { AppContext } from './context/SettingContext'


import Footer from "./footer";
import AppErrorBoundry from "./common/error/appErrorBoundry"
const Layout = ({ children }) => {



  const data = useStaticQuery(graphql`
    query {
    site {
      siteMetadata {
        title
        description
        author {
          name
        }
      }
    }
    settings:mdx(frontmatter: {collection_type: {eq: "Settings"}, title: {eq: "Settings"}}) {
      setting:frontmatter {
        title
        socialMedia {
          fb
          twitter
          youtube
          whatsapp
          email
        }
        pagination {
          pagination
        }
      }
    }
  }
   `)



  return (
    <AppContext.Provider value={data.settings.setting}>
      <AppErrorBoundry>
        <ThemeProvider theme={theme}>
          <div className="antialiased box-border text-gray-900 bg-gray-100 font-serif">
            <Header siteTitle={`${data.site.siteMetadata.title}`} />
            <div >
              <main className=" font-serif pt-28" style={{ backgroundColor: '#fffffe' }}>{children}</main>
              <Footer title={data.site.siteMetadata.title} description={data.site.siteMetadata.description}></Footer>
            </div>
          </div>
        </ThemeProvider>
      </AppErrorBoundry>
    </AppContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
